'use client';

import { ChevronsUpDown } from 'lucide-react';

import { Button, cn } from 'ui';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'ui';
import { Popover, PopoverContent, PopoverTrigger } from 'ui';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export interface ComboboxOption {
  label: string;
  value: string;
  options?: ComboboxOption[];
  group?: string;
}

export function ContextAwareCombobox<T extends ComboboxOption>({
  render: RenderOption,
  value,
  loadOptions,
  onSelect,
  placeholder,
  emptyStateMessage,
  defaultOptions = [],
  icon,
  disabled = false,
  dependencyState = [],
  defaultValue,
  prefetch = false,
  resetReactiveValue,
  withClearOnReset = false,
  classNames,
}: {
  icon?: React.ReactNode;
  loadOptions: (search: string) => Promise<T[]>;
  onSelect: (value?: T) => void;
  placeholder: string;
  emptyStateMessage: string;
  render: React.FC<T>;
  value?: string;
  defaultOptions: ComboboxOption[];
  disabled?: boolean;
  dependencyState?: string[];
  defaultValue?: ComboboxOption | null | undefined;
  prefetch?: boolean;
  resetReactiveValue?: string;
  withClearOnReset?: boolean;
  classNames?: {
    button?: string;
  };
}) {
  const [buttonWidth, setButtonWidth] = useState(0);

  const findOpt = useCallback(
    (opts: T[], target: string, key: keyof T) =>
      opts.find(
        (opt) =>
          opt[key]?.toString()?.toLowerCase() ===
          target?.toString().toLowerCase(),
      ),
    [],
  );

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [opts, setOpts] = useState<T[]>(
    !!defaultOptions && Array.isArray(defaultOptions) ? defaultOptions : [],
  );

  const [selected, setSelected] = useState<T | undefined>(() => {
    if (defaultValue) {
      return defaultValue;
    }

    if (value) {
      return findOpt(opts, value, 'value');
    }

    return undefined;
  });

  const wrappedFunc = useCallback(
    (search: string = '') => {
      loadOptions(search)
        .then((res) => {
          setOpts(res);
        })
        .finally(() => setIsLoading(false));
    },
    [...dependencyState],
  );

  const debounced = useCallback(debounce(wrappedFunc, 500), [
    ...dependencyState,
  ]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const isMounted = useRef(false);

  useEffect(() => {
    if (prefetch && !disabled) {
      loadOptions('').then(setOpts).catch(console.error);
    }
  }, [disabled]);

  console.log(
    'resetReactiveValue',
    resetReactiveValue,
    'placeholder',
    placeholder,
  );

  useEffect(() => {
    if (isMounted.current && withClearOnReset) {
      console.log(
        'reset combobox',
        placeholder,
        'due to change on field value: ',
        resetReactiveValue,
      );

      onSelect('');
      setSelected(undefined);

      if (prefetch && !disabled) {
        loadOptions('').then(setOpts).catch(console.error);
      }
    }

    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [resetReactiveValue]);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.clientWidth);
    }

    const handleResize = () => {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={buttonRef}
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className={cn('justify-between w-full', classNames?.button)}
          data-test-id='combobox'
        >
          {selected ? selected.label : placeholder}
          {icon || (
            <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{
          width: buttonWidth,
        }}
        className='p-0'
      >
        <Command shouldFilter={false}>
          <CommandInput
            disabled={disabled}
            loading={isLoading}
            placeholder={placeholder}
            onValueChange={(search) => {
              setIsLoading(true);
              debounced(search);
            }}
          />

          <CommandList>
            <CommandEmpty>
              {isLoading ? 'Buscando...' : emptyStateMessage}
            </CommandEmpty>
            {(opts || []).map((opt, index) => {
              if (opt.options) {
                return (
                  <CommandGroup key={index} heading={opt.label}>
                    {opt.options.length > 0 ? (
                      opt.options.map((groupOpt, index) => {
                        const value = `${groupOpt.value}-${opt.value}`;

                        return (
                          <CommandItem
                            key={index}
                            value={value}
                            onSelect={(currentValue) => {
                              onSelect(
                                currentValue === value ? '' : currentValue,
                              );

                              const option = findOpt(
                                opts,
                                currentValue,
                                'value',
                              );

                              console.log(
                                'option',
                                option,
                                'currentValue',
                                currentValue,
                              );

                              setSelected(option);
                              setOpen(false);
                            }}
                          >
                            <RenderOption {...groupOpt} group={opt.value} />
                          </CommandItem>
                        );
                      })
                    ) : (
                      <p className='text-sm pl-2'>
                        No se encontraron resultados
                      </p>
                    )}
                  </CommandGroup>
                );
              }

              return (
                <CommandItem
                  key={index}
                  value={opt.value}
                  onSelect={(currentValue) => {
                    console.log('opts, currentValue', opts, currentValue);
                    onSelect(opt);
                    setSelected(findOpt(opts, currentValue, 'value'));
                    setOpen(false);
                  }}
                >
                  <RenderOption {...opt} />
                </CommandItem>
              );
            })}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
