import { FileTypes } from 'database';
import { flatten } from 'lodash';
import { Plus } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { cn } from 'ui';
import { getHumanFileSize } from 'utils';

const TWENTY_MB_IN_BYTES = 20 * 1024 * 1024;

export const mimetypes: {
  [K in FileTypes]: string[];
} = {
  other: ['*'],
  pdf: ['application/pdf'],
  audio: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'],
  video: ['video/mpeg', 'video/ogg', 'video/webm', 'video/mp4'],
  image: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'],
  document: [
    'text/plain',
    'text/csv',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
};

export interface FileInputProps {
  invalid?: boolean;
  maxFiles?: number;
  disabled?: boolean;
  fileTypes?: FileTypes[];
  maxFileSize?: number;
  onDropFiles: (files: File[]) => void;
}

const fileTypesLabels: {
  [K in FileTypes]: {
    plural: string;
    singular: string;
  };
} = {
  pdf: {
    plural: 'PDFs',
    singular: 'un PDF',
  },
  document: {
    plural: 'documentos',
    singular: 'un documento',
  },
  image: {
    plural: 'imágenes',
    singular: 'una imagen',
  },
  audio: {
    plural: 'audios',
    singular: 'un audio',
  },
  video: {
    plural: 'videos',
    singular: 'un video',
  },
  other: {
    plural: 'archivos',
    singular: 'cualquier archivo',
  },
};

export const FileInput = ({
  fileTypes = ['image', 'document'],
  maxFileSize = TWENTY_MB_IN_BYTES,
  disabled,
  onDropFiles,
  maxFiles = 1,
  invalid,
}: FileInputProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const dataTransferItemsToFiles = useCallback(
    (dataTransferList: DataTransferItemList) => {
      const files: File[] = [];

      for (let i = 0; i < dataTransferList.length; i++) {
        const item = dataTransferList[i];

        const file = item.getAsFile();

        if (file) {
          files.push(file);
        }
      }

      return files;
    },
    [],
  );

  const supportedFileTypes = useMemo(
    () => flatten(fileTypes.map((type) => mimetypes[type])),
    [],
  );

  const handleFile = useCallback((files: File[]) => {
    if (!files.length) {
      return;
    }

    const filteredFiles = files
      .filter((f) => {
        const sizeCheck = f.size <= maxFileSize;

        const filetypeCheck = fileTypes.includes('other' satisfies FileTypes)
          ? true
          : supportedFileTypes.includes(f.type);

        return sizeCheck && filetypeCheck;
      })
      .slice(0, maxFiles);

    onDropFiles(filteredFiles);
  }, []);

  return (
    <div
      onDrop={(ev) => {
        ev.preventDefault();

        if (disabled) return;

        if (ev.dataTransfer.items) {
          const files = dataTransferItemsToFiles(ev.dataTransfer.items);

          handleFile(files);
        }
        setIsDragging(false);
      }}
      onDragLeave={() => setIsDragging(false)}
      onDragEnter={() => {
        if (!disabled) {
          setIsDragging(true);
        }
      }}
      onDragOver={(ev) => ev.preventDefault()}
      className={cn(
        'hover:border-slate-700 hover:[&>*]:text-slate-700 relative transition-all rounded-sm border border-dashed p-6',
        {
          'border-slate-700 [&>*]:text-slate-700': isDragging,
          'border-red-500': invalid,
        },
      )}
    >
      {!isDragging && (
        <input
          ref={inputRef}
          multiple={maxFiles > 1}
          onChange={(ev) => {
            if (ev.target.files?.length) {
              handleFile(Array.from(ev.target.files));
            }

            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }}
          className={cn('opacity-0 absolute inset-0 w-full h-full', {
            'pointer-events-none touch-none': isDragging,
          })}
          disabled={disabled}
          type='file'
          id='upload-file'
        />
      )}

      <div
        className={cn(
          '[&>*]:pointer-events-none flex text-neutral-300 items-center flex-col space-y-2',
          {
            'pointer-events-none cursor-not-allowed': !disabled,
          },
        )}
      >
        <Plus className='w-7 h-7' />
        <p className='text-xs text-center h-10'>
          Sube{' '}
          {fileTypes
            .map((t) =>
              maxFiles > 1
                ? fileTypesLabels[t].plural
                : fileTypesLabels[t].singular,
            )
            .join('/')}{' '}
          o arrójalo aquí. <br />
          <span className='text-[0.60rem]'>
            Límite {getHumanFileSize(maxFileSize)}
          </span>
        </p>
      </div>
    </div>
  );
};
